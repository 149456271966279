exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-de-agb-tsx": () => import("./../../../src/pages/de/agb.tsx" /* webpackChunkName: "component---src-pages-de-agb-tsx" */),
  "component---src-pages-de-blog-tsx": () => import("./../../../src/pages/de/blog.tsx" /* webpackChunkName: "component---src-pages-de-blog-tsx" */),
  "component---src-pages-de-changelog-tsx": () => import("./../../../src/pages/de/changelog.tsx" /* webpackChunkName: "component---src-pages-de-changelog-tsx" */),
  "component---src-pages-de-datenschutz-tsx": () => import("./../../../src/pages/de/datenschutz.tsx" /* webpackChunkName: "component---src-pages-de-datenschutz-tsx" */),
  "component---src-pages-de-faq-tsx": () => import("./../../../src/pages/de/faq.tsx" /* webpackChunkName: "component---src-pages-de-faq-tsx" */),
  "component---src-pages-de-impressum-tsx": () => import("./../../../src/pages/de/impressum.tsx" /* webpackChunkName: "component---src-pages-de-impressum-tsx" */),
  "component---src-pages-de-index-tsx": () => import("./../../../src/pages/de/index.tsx" /* webpackChunkName: "component---src-pages-de-index-tsx" */),
  "component---src-pages-de-preise-tsx": () => import("./../../../src/pages/de/preise.tsx" /* webpackChunkName: "component---src-pages-de-preise-tsx" */),
  "component---src-pages-en-blog-tsx": () => import("./../../../src/pages/en/blog.tsx" /* webpackChunkName: "component---src-pages-en-blog-tsx" */),
  "component---src-pages-en-dataprotection-tsx": () => import("./../../../src/pages/en/dataprotection.tsx" /* webpackChunkName: "component---src-pages-en-dataprotection-tsx" */),
  "component---src-pages-en-imprint-tsx": () => import("./../../../src/pages/en/imprint.tsx" /* webpackChunkName: "component---src-pages-en-imprint-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-pricing-tsx": () => import("./../../../src/pages/en/pricing.tsx" /* webpackChunkName: "component---src-pages-en-pricing-tsx" */),
  "component---src-pages-en-terms-tsx": () => import("./../../../src/pages/en/terms.tsx" /* webpackChunkName: "component---src-pages-en-terms-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2019-12-warum-apocha-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2019/12/warum_apocha.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2019-12-warum-apocha-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-01-apocha-bei-facebook-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2020/01/apocha_bei_facebook.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-01-apocha-bei-facebook-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-01-kategorien-uebersicht-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2020/01/kategorien_uebersicht.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-01-kategorien-uebersicht-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-01-wochen-budget-mit-der-umschlagmethode-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2020/01/wochen_budget_mit_der_umschlagmethode.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-01-wochen-budget-mit-der-umschlagmethode-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-03-belegerkennung-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2020/03/belegerkennung.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-03-belegerkennung-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-03-los-gehts-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2020/03/los_gehts.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-03-los-gehts-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-11-digitale-kassenbons-von-lidl-plus-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2020/11/digitale_kassenbons_von_lidl_plus.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-11-digitale-kassenbons-von-lidl-plus-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-11-kassenbon-wird-nicht-erkannt-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2020/11/kassenbon_wird_nicht_erkannt.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-11-kassenbon-wird-nicht-erkannt-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-11-plaene-und-preise-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2020/11/plaene_und_preise.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-11-plaene-und-preise-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-12-guthaben-schulden-und-wertgegenstaende-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2020/12/guthaben-schulden-und-wertgegenstaende.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-12-guthaben-schulden-und-wertgegenstaende-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-12-kostenlos-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2020/12/kostenlos.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-12-kostenlos-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-12-pdf-rechnungen-von-amazon-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2020/12/pdf_rechnungen_von_amazon.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2020-12-pdf-rechnungen-von-amazon-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2021-06-ecoscore-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2021/06/ecoscore.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2021-06-ecoscore-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2021-08-anonymer-upload-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2021/08/anonymer_upload.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2021-08-anonymer-upload-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2021-08-digitale-kassenbons-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2021/08/digitale_kassenbons.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2021-08-digitale-kassenbons-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2021-09-dm-ebon-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2021/09/dm_ebon.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2021-09-dm-ebon-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2021-10-edeka-kassenbons-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2021/10/edeka_kassenbons.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2021-10-edeka-kassenbons-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2022-11-wie-suche-in-belegen-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2022/11/wie-suche-in-belegen.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2022-11-wie-suche-in-belegen-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2022-12-vier-gruende-fuer-ein-digitales-haushaltsbuch-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2022/12/vier-gruende-fuer-ein-digitales-haushaltsbuch.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2022-12-vier-gruende-fuer-ein-digitales-haushaltsbuch-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-01-apocha-roadmap-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2023/01/apocha-roadmap.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-01-apocha-roadmap-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-01-apocha-zieht-um-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2023/01/apocha_zieht_um.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-01-apocha-zieht-um-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-02-kassenbon-scanner-app-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2023/02/kassenbon-scanner-app.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-02-kassenbon-scanner-app-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-02-wie-fuehre-ich-ein-haushaltsbuch-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2023/02/wie-fuehre-ich-ein-haushaltsbuch.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-02-wie-fuehre-ich-ein-haushaltsbuch-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-05-monatliche-ausgaben-je-kategorie-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2023/05/monatliche-ausgaben-je-kategorie.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-05-monatliche-ausgaben-je-kategorie-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-05-pdf-import-von-tesla-enbw-dm-drogerie-markt-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2023/05/pdf-import-von-tesla-enbw-dm-drogerie-markt.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-05-pdf-import-von-tesla-enbw-dm-drogerie-markt-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-06-vermoegensuebersicht-ganz-einfach-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2023/06/vermoegensuebersicht-ganz-einfach.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-06-vermoegensuebersicht-ganz-einfach-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-07-wie-berechne-ich-meine-sparquote-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2023/07/wie-berechne-ich-meine-sparquote.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-07-wie-berechne-ich-meine-sparquote-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-08-digitale-belege-per-email-importieren-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2023/08/digitale-belege-per-email-importieren.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-08-digitale-belege-per-email-importieren-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-08-digitale-kassenbons-mit-icloud-mail-weiterleiten-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2023/08/digitale-kassenbons-mit-icloud-mail-weiterleiten.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-08-digitale-kassenbons-mit-icloud-mail-weiterleiten-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-10-automatisches-haushaltsbuch-mit-gmail-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2023/10/automatisches-haushaltsbuch-mit-gmail.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-10-automatisches-haushaltsbuch-mit-gmail-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-10-epap-belege-importieren-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2023/10/epap-belege-importieren.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2023-10-epap-belege-importieren-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2024-02-apocha-an-intelligent-expense-tracker-with-receipt-scanner-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2024/02/apocha-an-intelligent-expense-tracker-with-receipt-scanner.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2024-02-apocha-an-intelligent-expense-tracker-with-receipt-scanner-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2024-03-the-best-expense-tracker-and-budgeting-app-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2024/03/the-best-expense-tracker-and-budgeting-app.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2024-03-the-best-expense-tracker-and-budgeting-app-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2024-03-transaktionen-ohne-konto-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2024/03/transaktionen-ohne-konto.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2024-03-transaktionen-ohne-konto-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2024-06-lastschriften-planen-kontoueberziehungen-vermeiden-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/olaf/workspace/apocha-application/apocha-websites/apocha-info/src/blog/posts/2024/06/lastschriften-planen-kontoueberziehungen-vermeiden.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-blog-posts-2024-06-lastschriften-planen-kontoueberziehungen-vermeiden-mdx" */)
}

