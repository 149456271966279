const theme = {
    colors: {
        background: "#ffffff",
        error: "hsl(3, 89%, 42%)",
        primary: "hsl(214, 100%, 43%)",
        secondary: "#0000ff",
        success: "hsl(145, 85%, 25%)",
        text: "hsla(214, 40%, 16%, 0.94)",
    },
    margins: {
        xs: "4px",
        s: "8px",
        m: "16px",
        l: "24px",
        xl: "40px",
    }
}

export default theme